import { defineStore } from "pinia";

export const useOnboardingStepsStore = defineStore("onboardingSteps", {
  state: () => ({
    register: {},
    step1: { completed: false },
    step2: { completed: false },
    step3: { completed: false },
    step4: { completed: false },
    step5: { completed: false },
  }),
  actions: {
    UPDATE_REGISTER_INFO(registerInfo) {
      this.register = registerInfo;
    },
    UPDATE_STEP1_INFO(step1Info) {
      this.step1 = { ...step1Info, completed: true }; // Mark step1 as completed
    },
    UPDATE_STEP2_INFO(step2Info) {
      this.step2 = { ...step2Info, completed: true }; // Mark step2 as completed
    },
    UPDATE_STEP3_INFO(step3Info) {
      this.step3 = { ...step3Info, completed: true }; // Mark step3 as completed
    },
    UPDATE_STEP4_INFO(step4Info) {
      this.step4 = { ...step4Info, completed: true }; // Mark step4 as completed
    },
    UPDATE_STEP5_INFO(step5Info) {
      this.step5 = { ...step5Info, completed: true }; // Mark step5 as completed
    },
  },
});

import { createApp } from "vue";
import App from "./App.vue";

/** Bootstrap  */
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

/** Router */
import router from "./router/router";
import menuItems from "./router/router";

/** Charts */
import VueApexCharts from "vue3-apexcharts";

/** Pinia (Vue State Management) */
import { createPinia } from "pinia";

/** Axios */
import axios from "axios";

/** PrimeVue */
import PrimeVue from "primevue/config";
import "primevue/resources/themes/lara-light-indigo/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core CSS
import Tooltip from "primevue/tooltip";

/** Data Tables */
import Vue3Datatable from "@bhplugin/vue3-datatable";

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

/** Remixicon */
import "../src/assets/remixicon/fonts/remixicon.css";
// import "remixicon/fonts/remixicon.css";

/** Toast */
import ToastPlugin from "vue-toast-notification";

import CKEditor from "@ckeditor/ckeditor5-vue";

import { useAuthStore } from "./store/auth";

import { isLoading } from "./store/LoadingState";
const app = createApp(App);

app.use(createPinia());

app.component("VueDatePicker", VueDatePicker);
app.component(Vue3Datatable);
app.use(router, menuItems);
app.use(CKEditor);
app.use(PrimeVue);
// app.use(bootstrap);
app.directive("tooltip", Tooltip);
app.use(VueApexCharts);
app.use(ToastPlugin);
app.mount("#app");

let baseURL = "https://app-qa.rdep.io";

if (
  process.env.NODE_ENV !== "development" &&
  process.env.VUE_APP_BASE_URL !== ""
) {
  baseURL = process.env.VUE_APP_BASE_URL;
}
axios.defaults.baseURL = baseURL;
//here we are providing the base usl globally
app.config.globalProperties.$http = axios;

// app.prototype.$http.defaults.baseURL = baseURL;

// Create an Axios interceptor
let isLoggingOut = false;

// Request interceptor
axios.interceptors.request.use(
  (config) => {
    isLoading.value += 1; // Show spinner when request starts
    // isLoading.value = true; // Show spinner when request starts
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axios.interceptors.response.use(
  // (response) => response,
  (response) => {
    if (response) {
      if (isLoading.value > 0) {
        isLoading.value -= 1; // Hide spinner when request completes
      }
      // setTimeout(() => {
      // });
    }
    return response;
  },
  (error) => {
    if (error.response) {
      if (isLoading.value > 0) {
        isLoading.value -= 1; // Hide spinner when request completes
      }
      if (error.response.status === 401 && !isLoggingOut) {
        isLoggingOut = true;
        const authStore = useAuthStore();
        authStore.clearAuthState();
        isLoggingOut = false;
      }
    }

    return Promise.reject(error);
  }
);
